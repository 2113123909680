<template>
	<div>
		<!-- 公共头部 -->
	<Header></Header>
		
		<div class="section1">
			<img class="pic" :src="newsInfo.img" />
			<div class="content">
				<h2 class="title">{{newsInfo.title}}</h2>
				<div class="desc">{{newsInfo.description}}</div>
			</div>
		</div>
		<div class="section2">
			<h2 class="cate-title">详细信息</h2>
			<div class="singlepage" v-html="newsInfo.content"></div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				newsInfo: ''
			}
		},
		watch: {
			$route(to,form) {
				if(to.path == form.path){
					this.getNewsInfo();
				}
			}
		},
		activated() {
			this.getNewsInfo();
		},
		deactivated() {
			this.newsInfo = '';
		},
		methods: {
			async getNewsInfo() {
				var id = this.$route.query.aid;
				var data = {
					id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/subject/detail', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('培训项目详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var newsInfo = res.data;
					if(newsInfo.img && newsInfo.img.substring(0,4) != 'http'){
						newsInfo.img = this.$store.state.http + newsInfo.img
					}
					this.newsInfo = newsInfo;
				} else {
					this.newsInfo = '';
				}
			}
		},
	};
</script>

<style scoped>
	.section1{
		font-size: 0;
	}
	.section1 .pic{
		width: 100%;
	}
	.section1 .content{
		background: #ffffff;
		padding: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .content .title{
		font-size: 0.32rem;
		color: #333333;
		font-weight: bold;
	}
	.section1 .content .desc{
		font-size: 0.24rem;
		color: #666666;
		line-height: 0.42rem;
		margin-top: 0.24rem;
	}
	
	.section2{
		margin-top: 0.33rem;
		margin-bottom: 0.7rem;
	}
	.section2 .cate-title{
		height: 0.9rem;
		display: flex;
		align-items: center;
		font-size: 0.32rem;
		color: #333333;
		padding-left: 0.5rem;
		font-weight: bold;
		position: relative;
	}

	.section2 .singlepage{
		font-size: 0.24rem;
		color: #666666;
		line-height: 0.5rem;
		background: #ffffff;
		padding: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
</style>